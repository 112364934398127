<template>
  <div class="home">
    <div class="filter-wrapper">
      <div>
        <call-input
          v-model="dataSearch"
          type-input="search"
          placeholder="Search"
          @input="onSearch">
          <template slot="prefix">
            <font-awesome-icon
              :icon="dataIconSearch" />
          </template>
        </call-input>
        <v-select
          v-model="dataSelectRoleValue"
          :options="computedSelectOptionsRoles"
          :searchable="false"
          placeholder="All Roles"
          :class="['call-select', dataSelectRoleValue ? 'call-select--value' : null]"
          :components="{Deselect: dataComponentDeselect}"
          @input="onSelectTeamsAndRole">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="onSelectTeamsAndRole">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :page-value="50"
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          v-if="computedIsAdmin"
          :icon="dataAddIcon"
          type="success"
          @click="clickShowAddUser()">
          Add User
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="computedUserFields"
        :items="dataUserList"
        @sort-changed="onSortTabel"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template #cell(name)="data">
          {{ data.item.name }}
          <b-badge
            v-if="data.item.role === 'admin'"
            variant="primary">
            Admin
          </b-badge>
          <b-badge
            v-else-if="data.item.role === 'lead'"
            variant="success">
            Team Leader
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(teams)="data">
          {{ data.item.teams | joinName }}
        </template>
        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="data.item.is_blocked"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(actions)="data">
          <call-table-actions
            v-show="data.item.actions"
            :user-id="data.item.id"
            :block="data.item.is_blocked"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
    <call-modal-add-user
      v-model="dataShowAddUser"
      @on-update="() => $_ajax()" />
    <call-modal-update-user
      v-model="dataShowUpdateUser"
      :user-id="dataIdUpdateUser"
      @on-update="() => $_ajax()" />
  </div>
</template>

<script>
import { BBadge, BTable } from 'bootstrap-vue';
import userTable from '../../../mixins/table/userTable';
import { faCheck, faLock, faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import CallInput from '../../../components/common/CallInput';
import CallModalAddUser from '../../../components/common/modals/CallAddUser';
import CallTableActions from '../../../components/desktop/CallTableActions';
import { mapGetters } from 'vuex';
import { TEAMS_GET_ALL, USERS_BLOCKED, USERS_GET_ALL } from '../../../store/types/actions.types';
import debounce from 'lodash/debounce';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import selectRoles from '../../../mixins/select/selectRoles';
import CallModalUpdateUser from '../../../components/common/modals/CallUpdateUser';
import CallPagination from '../../../components/desktop/CallPagination';
import CallButton from '../../../components/common/CallButton';
import helper from '../../../service/helper';
import { ID_ROLE_TEAM_LEADER } from '../../../service/consts';

export default {
  name: 'PageUsers',
  components: {
    CallButton,
    CallPagination,
    CallModalUpdateUser,
    CallTableActions,
    CallModalAddUser,
    CallInput,
    BTable,
    BBadge
  },
  mixins: [userTable, selectAllTeams, selectRoles],
  data () {
    return {
      dataAddIcon: faUserPlus,
      dataIconSearch: faSearch,
      dataIconCheck: faCheck,
      dataIconLock: faLock,
      dataShowAddUser: false,
      dataShowUpdateUser: false,
      dataIdUpdateUser: null,
      dataUserList: [],
      dataSearch: null,
      dataDesc: null,
      dataOrderBy: null,
      dataObjPagination: {}
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin'
    }),
    computedUserFields () {
      return this.dataMixinUserFields.filter(item => item.key !== 'call_mode');
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'role',
            'search',
            'teamId',
            'desc',
            'orderBy',
            'limit',
            'page'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'role':
                  this.dataSelectRoleValue = options[_key];
                  break;
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ role = this.dataSelectRoleValue, search = this.dataSearch, teamId = this.dataSelectTeamsValue, desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
          params: {
            role: role?.value,
            search,
            team_ids: [teamId?.id],
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page
          }
        });
        this.dataObjPagination = paging;
        this.dataUserList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              role,
              search,
              teamId,
              desc,
              orderBy,
              limit: _limit,
              page: _page
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajax()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    clickShowAddUser () {
      this.dataShowAddUser = true;
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    onSearch: debounce(function () {
      this.$_ajax({ reset: true });
    }, 1200),
    onSelectTeamsAndRole () {
      this.$_ajax({ reset: true });
    },
    async  onActions ({ id: userId, is_blocked: isBlocked, name = '', teams = [], role = null }, action) {
      switch (action) {
        case 'update':
          this.dataIdUpdateUser = userId;
          this.dataShowUpdateUser = true;
          break;
        case 'view':
          this.$router.push({
            name: 'UserDetail',
            params: {
              id: userId
            },
            query: {
              userName: name
            }
          });
          break;
        case 'blocked':
          try {
            if (!helper.isEmpty(teams) && role === ID_ROLE_TEAM_LEADER) {
              this.$Notify({
                title: 'Oops',
                message: 'This user is a team leader. You should demote him firstly.',
                type: 'warning'
              });
              return;
            }
            await this.$store.dispatch(`user/${USERS_BLOCKED}`, {
              params: {
                userId
              }
            });
            await this.$_ajax();
            this.$Notify({
              title: 'User',
              message: `Successfully ${!isBlocked ? 'locked' : 'unlocked'}`,
              type: 'success'
            });
          } catch (e) {
            throw Error('Ошибка блокировки');
          }
          break;
      }
    }
  }
};
</script>
