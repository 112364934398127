import { ID_ROLE_ADMIN, ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../service/consts';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      dataSelectRoleValue: null,
      dataIconDown: faChevronDown,
      dataComponentDeselect: {
        render: createElement => createElement(FontAwesomeIcon, {
          props: {
            icon: faTimes
          }
        })
      },
      dataSelectOptionsRoles: [
        {
          label: 'Administration',
          value: 'admin'
        },
        {
          label: 'Team Leader',
          value: ID_ROLE_TEAM_LEADER
        },
        {
          label: 'Operator',
          value: ID_ROLE_OPERATOR
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin'
    }),
    computedSelectOptionsRoles () {
      if (this.computedIsAdmin) {
        return this.dataSelectOptionsRoles;
      }
      return this.dataSelectOptionsRoles.filter(item => (item.value !== ID_ROLE_ADMIN));
    }
  }
};
