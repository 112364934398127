import { ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../service/consts';

export default {
  name: 'CallCheckedGroup',
  props: {
    value: {
      type: String,
      default: ID_ROLE_OPERATOR
    }
  },
  data () {
    return {
      dataModelChecked: this.value
    };
  },
  watch: {
    dataModelChecked () {
      this.$emit('input', this.dataModelChecked);
    },
    value () {
      this.dataModelChecked = this.value;
    }
  },
  render (h) {
    return (
      <div class="switcher-wrapper">
        <div class="switcher-item">
          <input
            id="radio-1"
            vModel={this.dataModelChecked}
            value={ID_ROLE_OPERATOR}
            type="radio"
            name="radio">  </input>
          <label for="radio-1">Operator</label>
        </div>
        <div class="switcher-item">
          <input
            id="radio-2"
            vModel={this.dataModelChecked}
            value={ID_ROLE_TEAM_LEADER}
            type="radio"
            name="radio"> </input>
          <label for="radio-2">Team Leader</label>
        </div>
      </div>
    );
  }
};
